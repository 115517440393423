<i18n>
[
    "global__success"
]
</i18n>

<template>
    <BaseButton
        :id="$attrs.id"
        v-tab-focus="onClick"
        :isLoadingButton="true"
        :isLoading="isLoading"
        :isLoaded="isLoaded"
        :disabled="$attrs.disabled"
        :variant="variant"
        :colorVariant="colorVariant"
        :type="type"
        :labelText="labelText"
        :useSmallFont="useSmallFont"
        :isBorderless="isBorderless"
    >
        <template v-if="isLoading">
            <LoadingDots :isLoading="isLoading" />
        </template>

        <template v-else-if="isLoaded">
            <BaseIcon
                class="c-loading-button__icon"
                icon="global--checkmark"
                size="12px"
            />
            <slot name="loaded">
                {{ t('global__success') }}
            </slot>
        </template>
        <template v-else>
            <slot></slot>
        </template>
    </BaseButton>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

import buttonProps from '~coreModules/core/components/mixins/buttonProps';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';
import LoadingDots from '~coreModules/core/components/ui/LoadingDots.vue';

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable no-undef */
defineOptions({
    mixins: [buttonProps],
});

defineProps({
    type: {
        type: String,
        required: false,
        default: 'button',
    },
    labelText: {
        type: String,
        required: false,
        default: '',
    },
});

const emit = defineEmits(['click']);

const { t } = useI18n();

function onClick(e) {
    emit('click', e);
}
</script>

<style lang="scss">
    .c-loading-button {
        &__icon {
            margin-top: 2px;
        }
    }
</style>
